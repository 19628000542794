<template>
  <div>
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.mdAndUp, 'subheading': $vuetify.breakpoint.smAndDown}"
    />
    <v-layout align-center>
      <div class="font-weight-light"
           :class="{'headline': $vuetify.breakpoint.mdAndUp, 'body-1': $vuetify.breakpoint.smAndDown}"
      >
        <span class="subhead">E</span>xplore our library of meticulously crafted lessons on the subject of mechanics of fluids.
        <br>
        <br>
      </div>
    </v-layout>
    <v-container grid-list-xl text-xs-center>
      <v-layout
        align-center
        justify-center
        row
      >
        <v-flex v-for="topic in topics"
                :key="topic.key"
                xs12
                sm12
                md12
                lg12
                xl12
        >
          <subtopic-card
            :headline="topic.headline"
            :description="topic.description"
            :img="topic.img"
            :path="topic.path"
          />
        </v-flex>
      </v-layout>
    </v-container>
    <br>
    <br>
    <br>
  </div>
</template>
<script>
import SubtopicCard from '../../../views/SubTopics.vue'
export default {
  name: 'FluidMechanics',
  components: {
    SubtopicCard
  },
  props: {
      myStyle: {
          type: Object,
          default: () => {}
      },},
  data () {
    return {
      topics: [
        {
          headline: "Bernoulli Equation", description: "Principle based on conservation of energy that describes the variations in fluid pressure with its velocity and altitude",
          img: "/assets/wdice-1.svg", path: "/physics/fluids/bernoulli", index: "hidden"
        },
        {
          headline: "Capillary Effect", description: "Effect that explains the rise of fluid column in narrow channels due to action of surface tension",
          img: "/assets/wdice-2.svg", path: "/physics/fluids/capillary", index: "hidden"
        },
        {
          headline: "Continuity Equation", description: "Thin zones of high shear stress that are formed during flow of fluids around solid objects",
          img: "/assets/wdice-3.svg", path: "/physics/fluids/continuity", index: "hidden"
        },
        {
          headline: "Airfoil Theory", description: "Mathematical transformation that maps an airfoil to a cylinder",
          img: "/assets/wdice-4.svg", path: "/physics/fluids/joukowsky", index: "hidden"
        }
      ]
    }
  },
  created () {
    this.$store.commit('navigation/resetState');
    const title = 'Fluid Mechanics';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Bernoulli Equation',img:'/assets/number-1.svg', action: () => this.$router.push({path: 'fluid-mechanics/bernoulli'})},
      {title: 'Capillary Effect', img:'/assets/number-2.svg', action: () => this.$router.push({path: 'fluid-mechanics/capillary_effect'})},
      {title: 'Continuity Equation', img:'/assets/number-3.svg', action: () => this.$router.push({path: 'fluid-mechanics/continuity`'})},
      {title: 'Joukowsky Transformation', img:'/assets/number-4.svg', action: () => this.$router.push({path: 'fluid-mechanics/joukowsky'})},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
  },
  methods: {
  }
}
</script>
<style scoped lang="scss">
.subhead {
color: #903;
float: left;
font-family: Georgia;
font-size: 75px;
line-height: 60px;
padding-top: 4px;
padding-right: 8px;}
</style>
